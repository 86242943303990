.button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    border-radius: 12px;
    padding: 13px;
    border: 1px solid #E4E7F2;
    height: 50px;
    background-color: transparent;
}

.text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #3B4168;
}
