.modalContentBlock{
    height: calc(90vh - 6.4rem);
    display: flex;
    align-items: center;
}

.modalContent{
    width: 52rem;
    height: 25.2rem;
    background: #fff;
    border-radius: 1.5rem;
    text-align: center;
    padding: 4rem 0;
}

.content__text {
    font-size: 2rem;
    line-height: 2;
    color: #3B4168;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
}

.content__text2 {
    color: #909EBB;
    font-size: 1.4rem;
    margin-top: 0.7rem;
    line-height: 1.4;
}

.content__img {
    margin: 0 23rem 2.4rem;
}