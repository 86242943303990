._container {
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

._body {
  text-align: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  max-width: 540px;
  width: 100%;
  max-height: 576px;
  height: 100%;
  position: relative;
}

.block_title_color {
  color: #EB5757;
}

._text {
  margin-top: 20px;
  text-align: center;
  font-size: 15px;
  color: #3B4168;
  line-height: 20px;
  max-width: 42rem;
}

._text_link {
  font-size: 15px;
}

.block_line {
  border-top: solid 1px #E4E7F2;
  margin-top: 20px;
}

.block_footer {
  margin-top: 20px;
}

.button_send_form {
  margin-top: 20px;
  border-radius: 5px;
  width: 100%;
}