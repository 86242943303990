._container {
    top: 0;
    left: 0;
    margin-top: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
}

._body {
    text-align: center;
    background-color: white;
    box-shadow: 0 4px 15px 0 rgba(33, 36, 55, 0.12);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 64px;
    max-width: 600px;
    width: 100%;
    height: 100%;
    position: relative;
}

._text {
    text-align: center;
    font-size: 16px;
    color: #3B4168;
    margin-bottom: 12px;
    line-height: 24px;
    max-width: 420px;
}

._modalText {
    margin: 0;
}

._title_text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    margin: 28px 0 40px;
    color: #3B4168;
}

._phone {
    color: #0071CE;
}

.repeat {
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 40px;
}

.resend {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 40px;
}

.resend .resendBtn {
    margin: 0 4px;
    color: #0071CE;
    padding: 0;
    opacity: 0.5;
}
.resend .resendBtnActive{
    opacity: 1;
}

.counter {
    color: #3B4168;
    font-size: 1.4rem;
    line-height: 1.428;
}

.icon {
    margin-right: 1.8rem;
}

.sendButton {
    width: 100%;
    border-radius: 16px;
    color: #ffffff;
    padding: 13px;
    height: auto;
}

.sendButton span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-transform: initial;
}

._close {
    position: absolute;
    font-size: 2.5rem;
    right: 3rem;
    top: 3rem;
    cursor: pointer;
}

.text_warning {
    color: #EB5757;
}

.form {
    width: 100%;
}

.input {
    border: 1px solid #E4E7F2;
    border-radius: 16px;
    padding: 16px 24px;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    background: #FBFDFF;
    font-family: 'Montserrat', sans-serif;
    max-width: 34.5rem;
    text-align: center;
}

