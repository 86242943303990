.colWidth {
    width: 100%;
}

.title {
    margin-bottom: 12px;
    text-align: center;
}

.titleText {
    font-size: 16px;
    line-height: 24px;
    color: #3B4168;
}

.notes {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 32px;
}

.notesText {
    color: #6F7C98;
}

.inpGroup {
    display: flex;
    border: 1px solid #E4E7F2;
    border-radius: 16px;
    background-color: #FAFAFF;
}

.formBlock .inpGroup :global(.ant-select-selector) {
    height: 56px;
    width: 120px;
    border: none;
    border-radius: 16px;
    background-color: transparent;
    padding: 16px 20px;
}

.formBlock .inpGroup :global(.ant-select-selection-item) {
    color: #6F7C98;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 0;
}

.inpGroup :global(.ant-select-arrow) {
    display: none;
}

.formBlock .inpGroup :global(.ant-input-affix-wrapper) {
    border: none;
    background-color: transparent;
}

.line {
    margin-top: 16px;
    height: 20px;
    border: 1px solid #E4E7F2;
}

.drots {
    cursor: pointer;
    position: absolute;
    top: 25px;
    left: 98px;
}

.buttonSubmit {
    border-radius: 12px;
    color: #ffffff;
    padding: 13px;
    height: auto;
}

.buttonSubmit span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-transform: initial;
}

.errors {
    position: absolute;
    bottom: -25px;
    width: 100%;
    text-align: center;
}

.lineOr {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;
    text-transform: uppercase;
    color: #6F7C98;
    margin: 24px 0;
}

.lineOr span {
    height: 1px;
    width: 100%;
    border-top: 1px solid #E4E7F2;
}

.modal :global(.ant-modal-body) {
    padding-bottom: 20px;
}

.modal :global(.ant-modal-footer) {
    padding-top: 0;
}

.cookiesBlock {
    background: #ffffff;
    border-radius: 24px;
    width: 880px;
    padding: 20px;
    position: fixed;
    bottom: 20px;
    z-index: 999;
    box-shadow: 0 4px 15px 0 rgba(33, 36, 55, 0.12);

    display: none;
}

.block {
    display: flex;
    align-items: flex-start;
}

.text {
    font-size: 16px;
    line-height: 24px;
    margin: 0 20px 20px 0;
}

.btnOk {
    width: 122px;
    border-radius: 8px;
}

.visible {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}