.button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
    border: 1px solid #0D4CD3;
    border-radius: 10px;
    padding: 12px 24px;
    margin-bottom: 20px;
    height: 52px;
    background-color: transparent;
}

.text {
    color: #0D4CD3;
    font-size: 20px;
}
