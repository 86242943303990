.title {
    margin-bottom: 24px;
    text-align: center;
}

.titleText {
    font-size: 16px;
    line-height: 24px;
    color: #3B4168;
}

.formBlock {
    margin-bottom: 16px;
}

.inpGroup {
    display: flex;
    border: 1px solid #E4E7F2;
    border-radius: 16px;
    background-color: #FAFAFF;
}

.formBlock .inpGroup :global(.ant-select-selector) {
    height: 56px;
    width: 120px;
    border: none;
    border-radius: 16px;
    background-color: transparent;
    padding: 16px 20px;
}

.formBlock .inpGroup :global(.ant-select-selection-item) {
    color: #6F7C98;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 0;
}

.inpGroup :global(.ant-select-arrow) {
    display: none;
}

.inpGroup :global(.ant-input-affix-wrapper) {
    border: none;
    background-color: transparent;
}

.line {
    margin-top: 16px;
    height: 20px;
    border: 1px solid #E4E7F2;
}

.drots {
    cursor: pointer;
    position: absolute;
    top: 25px;
    left: 98px;
}

.forgotPassword {
    text-align: center;
    margin-bottom: 40px;
}

.forgotPasswordLink {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    color: #0071CE;
    font-weight: 500;
    height: auto;
}

.buttonLogin {
    border-radius: 12px;
    color: #ffffff;
    padding: 13px;
    height: auto;
}

.buttonLogin span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-transform: initial;
}

.lineOr {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;
    text-transform: uppercase;
    color: #6F7C98;
    margin: 24px 0;
}

.lineOr span {
    height: 1px;
    width: 100%;
    border-top: 1px solid #E4E7F2;
}

.errors {
    position: absolute;
    bottom: -25px;
}

.modalForgotPassword :global(.ant-modal-content) {
    box-shadow: 52px 42px 60px -15px rgba(26, 117, 232, 0.03);
    border-radius: 15px;
}

.modalForgotPassword :global(.ant-modal-body) {
    padding: 48px 64px 0;
}

.modalForgotPassword :global(.ant-modal-footer) {
    padding: 0 64px 48px;
    text-align: center;
    border: none;
}

.btnWidth {
    width: 100%;
}

.emptyIcon {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
}

.emptyModalTitle {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    line-height: 1.2;
    color: #3B4168;
    margin-bottom: 16px;
}

.emptyModalText {
    color: #909EBB;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.428;
    margin-bottom: 32px;
    text-align: center;
}

.modalSendMessageSuccess {
    text-align: center;
}

.modalSendMessageSuccess h2 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    margin: 0;
}

.modalSendMessageSuccess :global(.ant-modal-content) {
    background: #51D54F;
    box-shadow: 52px 42px 60px -15px rgba(26, 117, 232, 0.03);
    border-radius: 0.4rem;
    display: inline-block;
}

.modalSendMessageSuccess :global(.ant-modal-body) {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalSendMessageSuccess :global(.ant-modal-body > span) {
    margin: 0 16px 0 0;
}

.modalSendMessageSuccess path {
    fill: #ffffff;
}