.recovery {
    width: 57.5rem;
    min-height: 52rem;
    padding: 4.8rem 6.4rem;
    margin-top: 14.5rem;
    border-radius: 1.5rem;
    background-color: #ffffff;
    margin-bottom: 6rem;
}

.title {
    color: #3B4168;
    font-size: 3.2rem;
    line-height: 1.25;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 4rem;
}

.text {
    font-size: 1.6rem;
    line-height: 1.25;
    color: #6F7C98;
    margin-bottom: 2rem;
    text-align: center;
}

.button {
    border-radius: 0.4rem;
    color: #ffffff;
    padding: 1.1rem;
    height: auto;
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.25;
    font-weight: 500;
}

.saveBlock {
    margin-bottom: 3.2rem;
}

.cancelBlock {
    width: 100%;
    text-align: center;
}

.buttonCancel {
    font-size: 1.6rem;
    line-height: 1.25;
    font-weight: 500;
    padding: 0;
    height: auto;
    border: none;
}