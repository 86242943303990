.loader {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 63px;
    left: 0;
    display: flex;
    justify-content: flex-start;
    z-index: 100000;
}

.loader__element {
    height: 3px;
    width: 100%;
    background: #a5d6a700;

}

.loader__element:before {
    content: '';
    display: block;
    background-color: #3d3bbe;
    height: 3px;
    width: 0;
    animation: getWidth 3s ease-in infinite;
}

@keyframes getWidth {
    100% {
        width: 100%;
    }
}