.modalForgotPassword :global(.ant-modal-content) {
    box-shadow: 52px 42px 60px -15px rgba(26, 117, 232, 0.03);
    border-radius: 15px;
}

.modalForgotPassword :global(.ant-modal-body) {
    padding: 48px 64px 0;
}

.modalForgotPassword :global(.ant-modal-footer) {
    padding: 0 64px 48px;
    text-align: center;
    border: none;
}

.modalBtnLink {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    height: 20px;
    padding: 0;
}

.modalTitle {
    color: #3B4168;
    font-size: 32px;
    line-height: 1.25;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
}

.modalText {
    font-size: 16px;
    line-height: 1.25;
    color: #3B4168;
    margin-bottom: 20px;
    text-align: center;
}

.modalGrey {
    background-color: #F5FBFF;
    padding: 12px 16px;
    border-radius: 0.8rem;
    color: #909EBB;
}

.formBlock {
    margin-bottom: 16px;
}

.inpGroup {
    display: flex;
    border: 1px solid #E4E7F2;
    border-radius: 16px;
    background-color: #FAFAFF;
}

.formBlock .inpGroup :global(.ant-select-selector) {
    height: 56px;
    width: 120px;
    border: none;
    border-radius: 16px;
    background-color: transparent;
    padding: 16px 20px;
}

.formBlock .inpGroup :global(.ant-select-selection-item) {
    color: #6F7C98;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 0;
}

.inpGroup :global(.ant-select-arrow) {
    display: none;
}

.inpGroup :global(.ant-input-affix-wrapper) {
    border: none;
    background-color: transparent;
}

.line {
    margin-top: 16px;
    height: 20px;
    border: 1px solid #E4E7F2;
}

.drots {
    cursor: pointer;
    position: absolute;
    top: 25px;
    left: 98px;
}

.buttonLogin {
    border-radius: 12px;
    color: #ffffff;
    padding: 13px;
    height: auto;
}

.buttonLogin span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-transform: initial;
}

.modalLine {
    font-size: 14px;
    line-height: 1.142;
    color: #6F7C98;
    height: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.modalLine:before {
    content: '';
    display: block;
    border-bottom: 1px solid #E4E7F2;
    width: 100%;
    max-width: 195px;
    height: 1px;
}

.modalLine:after {
    content: '';
    display: block;
    border-bottom: 1px solid #E4E7F2;
    width: 100%;
    max-width: 195px;
    height: 1px;
}