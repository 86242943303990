.auth {
    width: 600px;
    max-height: 83rem;
    padding: 60px 64px 52px;
    margin-top: 100px;
    border-radius: 24px;
    background-color: #ffffff;
}

.authTitle  {
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    line-height: 44px;
    color: #3B4168;
    font-weight: 700;
    text-align: center;
    padding: 28px 0 40px;
    margin-bottom: 0;
}

.auth :global(.ant-tabs-nav) {
    margin-bottom: 40px;
}

.auth :global(.ant-tabs-large .ant-tabs-nav .ant-tabs-nav-list) {
    width: 100%;
}

.auth :global(.ant-tabs-large .ant-tabs-nav .ant-tabs-tab) {
    padding: 10px 15px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.33;
    font-family: 'Montserrat', sans-serif;
    color: #BDBDBD;
    width: 50%;
    justify-content: center;
}

.auth :global(.ant-tabs-tab .ant-tabs-tab-btn) {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    font-family: Montserrat, sans-serif;
    color: #6F7C98;
}

.auth :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: #0071CE;
}

.auth :global(.ant-tabs-ink-bar) {
    background: #0071CE;
}

.auth :global(.ant-input) {
    font-size: 1.6rem;
    line-height: 1.25;
    color: #3B4168;
    text-align: center;
}

.auth :global(.ant-input::placeholder) {
    color: #909EBB;
}

.auth :global(.ant-input-affix-wrapper input.ant-input) {
    padding-left: 1.6rem;
}

.auth :global(.ant-input-affix-wrapper input[type=password]) {
    padding-left: 4rem;
}

.link {
    width: 100%;
    text-align: center;
    margin: 20px 0 0 0;
}

@media (max-width: 670px) {
    .auth {
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 50px;
        padding: 75px 25px;
    }
}
