.block {
    margin-top: 16.8rem;
    text-align: center;
}

.title {
    font-weight: 700;
    font-size: 32rem;
    line-height: 120%;
    color: #ffffff;
    text-shadow: 0 0.4rem 1.5rem rgba(0, 0, 0, 0.03);
    margin-bottom: 4rem;
    font-family: 'Montserrat', sans-serif;
}

.title__text {
    font-family: 'Montserrat', sans-serif;
    font-size: 3.2rem;
    line-height: 4rem;
    color: #3B4168;
    font-weight: 700;
    margin-bottom: 1.6rem;
}

.description {
    color: #6F7C98;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 4rem;
}

.btn {
    width: 13.7rem;
    height: 4.4rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
}