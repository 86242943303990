@import '~antd/dist/antd.css';

html, body {
    font-size: 10px;
    font-family: 'IBM Plex sans', sans-serif;
    height: auto;
}

body {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #F5F6F8;
}

.darkBody {
    background: none;
    background: #f0f2f5;
}

.lightBody {
    background-image: url("assets/image/Background-img.png");
}

@font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat-Bold.eot');
    src: url('assets/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('assets/fonts/Montserrat-Bold.woff') format('woff'),
    url('assets/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

._backgroundColor {
    background: none;
}

._backgroundHide {
    background-image: none;
    background: #f0f2f5;
}

#root {
    min-height: calc(100vh - 192px);
}

.ant-btn {
    transition: none;
}

.ant-btn-link {
    color: #0071CE;
}

.ant-btn-link:hover {
    color: #005EAC;
}


.ant-btn-primary {
    background: #0071CE;
    border: none;
    border-radius: 8px;
}

.ant-btn-primary:hover {
    background: #005EAC;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
    background: #EFEFEF;
    color: #909EBB;
}

.ant-btn-default {
    background: #EFF8FE;
    border-radius: 8px;
    border: none;
    padding: 0 20px;
    transition: none;
    color: #0071CE;
    font-weight: 500;
}

.ant-btn-default:hover, .ant-btn-default:focus {
    background: #EFF8FE;
    color: #0071CE;
}

.ant-btn-default[disabled],
.ant-btn-default[disabled]:hover {
    background: #EFF8FE;
    color: #0071CE;
}

.auth__tab-pane {
    font-size: 30rem;
}

.ant-input-affix-wrapper {
    padding: 16px 20px;
    border: 1px solid #E4E7F2;
    font-size: 16px;
    line-height: 24px;
    color: #3B4168;
    border-radius: 16px;
}

._clickLing {
    display: flex;
    justify-content: center;
    width: 200px;
    font-size: 17px;
    color: grey;
    cursor: pointer;
    border-bottom: 2px solid grey;
}

._clickLing:hover {
    display: flex;
    justify-content: center;
    width: 200px;
    font-size: 17px;
    color: grey;
    cursor: pointer;
    border-bottom: 2px solid grey;
}

._lightThemeFooter {
    color: white;
    background: #1d39c4;
}

.ant-form-item-label > .ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: #0071CE !important;
    position: absolute;
    margin: 0 !important;
    top: 7%;
    right: -1rem;
}

.ant-modal-content {
    border-radius: 16px !important;
}

.ant-message-notice-content {
    color: white;
    width: 67rem;
    border-radius: 16px;
    height: auto;
}

.messageSuccess .ant-message-notice-content {
    background: linear-gradient(90deg, #51D54F 0%, #15B012 100%);
}

.messageError .ant-message-notice-content {
    background: linear-gradient(90deg, #FF5F5F 0%, #EB5757 100%);
}

.messageWarning .ant-message-notice-content {
    background: linear-gradient(90deg, #FAA05A 0%, #FD9A1B 100%);
}

.messageInfo .ant-message-notice-content {
    background: white;
    color: black !important;
}

.ant-message-custom-content {
    margin-top: 7px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.ant-message-success {
    color: white;
}

.ant-message-custom-content > span {
    margin-left: 10px;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    box-shadow: none;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
}

@media screen and (max-width: 670px) {
    .ant-message-notice-content {
        width: 30rem;
    }

    .ant-message-custom-content {
        font-size: 11px;
    }
}

@media screen and (max-width: 670px) {
    .auth {
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 5rem;
        padding: 7.5rem 2.5rem;
    }
}
