.content {
    margin: 14rem 0 14rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
}

.errorIcon {
    margin-bottom: 3.2rem;
}

.message {
    text-align: center;
    max-width: 56rem;
    padding: 0 1rem;
}

.message h1 {
    color: #3B4168;
    font-size: 3.2rem;
    line-height: 4rem;
    font-weight: 700;
}

.message p {
    font-size: 1.8rem;
    font-weight: 500;
    color: #6F7C98;
}