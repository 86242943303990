.badGateway {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
}

.content {
    margin: 14rem 0 11rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.errorIcon {
    margin-bottom: 10.4rem;
}

.errorMessage {
    text-align: center;
    padding: 0 1rem;
}

.title {
    color: #3B4168;
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1.3rem;
}

.description {
    font-size: 1.7rem;
    font-weight: 500;
    color: #6F7C98;
    margin-bottom: 4rem;
}

.reloadBtn {
    font-size: 1.6rem;
    height: 4.4rem;
    font-family: 'IBM Plex sans', sans-serif;
    line-height: 2rem;
}